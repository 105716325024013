import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

import { Project } from './models/project';
import { ProjectService } from './services/project.service';

@Injectable()
export class Globals {

    fundspringHostname = environment.fundspringHostname;
    fundspringProjectPath = environment.fundspringProjectPath;
    vdAPIEndpointHostname = environment.vdAPIEndpointHostname;
    vdAPIEndpointProjectPath = environment.vdAPIEndpointProjectPath;
    vdAPIEndpointPath = {
        "dependencies":environment.vdAPIEndpointDependenciesPath || "vulnerability/dependencies",
        "dependencyGraph":environment.vdAPIEndpointDependencyGraphPath || "vulnerability/dependencies/tree",
        "licenses":environment.vdAPIEndpointLicensesPath || "vulnerability/licenses",
        "issues":environment.vdAPIEndpointIssuesPath || "vulnerability/issues",
        "summary":environment.vdAPIEndpointSummaryPath || "vulnerability/summary",
        "task":environment.vdAPIEndpointTaskPath || "task",
        "staticanalysis/issues":environment.vdAPIEndpointStaticIssuesPath || "staticanalysis/issues",
        "staticanalysis/summary":environment.vdAPIEndpointStaticSummaryPath || "staticanalysis/summary"
    };

    protocol = '//';
    projectInfo = null;
    vdAppLoaded = false;
    scrollingCallBack = null;
    scrollingCallBackObject = {};

    constructor(
        private ProjectService: ProjectService,
    ) { }

    initializeProject(externalId: string){
        this.ProjectService.getProjectInfo(
            this.protocol + [
                this.vdAPIEndpointHostname,
                this.vdAPIEndpointProjectPath
            ].join('/'),
            externalId 
        )
            .subscribe( data => {
                this.projectInfo = data;
                this.vdAppLoaded = true;
            });
    }

    getParamsFromFilters (issueFilters: object){
        var instanceAllTrue = true;
        var instanceArray = null;
        const searchParamObject = {};

        for(var issueFilterType in issueFilters){
            instanceAllTrue = true;
            instanceArray = []
            searchParamObject[issueFilterType] = "";

            if(typeof issueFilters[issueFilterType] === "number"){
                instanceAllTrue = false;
                instanceArray.push(issueFilters[issueFilterType]);
            } else {
                for(var issueFilterTypeValue in issueFilters[issueFilterType]){
                    if( !issueFilters[issueFilterType][issueFilterTypeValue] ){
                        instanceAllTrue = false;
                    } else {
                        instanceArray.push(issueFilterTypeValue);
                    }
                }
            }

            if(instanceAllTrue || !instanceArray.length){
                delete searchParamObject[issueFilterType];
            } else {
                searchParamObject[issueFilterType] = instanceArray.join(",");
            }
        }
        return searchParamObject;
    }

    updateFilterLanguage (filterObject: object){
        var filtersString = "";
        var shouldShowAllSelected = true;

        for(var filterType in filterObject){
            var allSelectedForFilterType = true;
            var selectedCountForFilterType = 0;
            for(var filters in filterObject[filterType]) {
                if (!filterObject[filterType][filters]) {
                    allSelectedForFilterType = false;
                    continue;
                }

                selectedCountForFilterType++;
            }

            if (selectedCountForFilterType === 0) {
                shouldShowAllSelected = false;
                continue
            }

            if (!allSelectedForFilterType) {
                shouldShowAllSelected = false;
            }

            if (filtersString) {
                filtersString += ", ";
            }

            filtersString += selectedCountForFilterType + " " + filterType;
        }

        if (shouldShowAllSelected) {
            filtersString = "All";
        }

        if (!filtersString) {
            filtersString = "None";
        }

        return filtersString;
    }

    getFundspringProjectUrl(fundsprintId: string){
        return this.protocol + [
            this.fundspringHostname,
            this.fundspringProjectPath,
            fundsprintId
        ].join('/');
    }

    getApiHostname(externalId: string){
        return this.protocol + [
            this.vdAPIEndpointHostname,
            this.vdAPIEndpointProjectPath,
            externalId
        ].join('/');
    }

    getApiPath(pathName: string){
        return this.vdAPIEndpointPath[pathName];
    }

    getAppLoaded(){
        return this.vdAppLoaded;
    }
    
    getExternalId(){
        return this.projectInfo && this.projectInfo.externalId;
    }
    
    getProjectInfo(){
        return this.projectInfo;
    }

    checkForProjectInfo(externalId: string): void {
        if(this.projectInfo === null){
            this.initializeProject(externalId);
        } else {
            // console.log("already set");
        }
    }

    startScrollingListener() {
        var footer, 
            footerOffsetHeight,
            self = this;

        window.onscroll = function(){
            var currentDataTypeElement,
                currentDataType;

            if(!footer){
                footer = document.getElementById('footer');
            }

            if(!footerOffsetHeight){
                footerOffsetHeight = footer.offsetHeight;
            }

            if(window.scrollY + window.innerHeight > document.body.offsetHeight-footerOffsetHeight){
                if(
                    (currentDataTypeElement = document.getElementById('currentDataDisplay')) &&
                    (currentDataType = currentDataTypeElement.getAttribute("data-table-type"))
                ){
                    self.scrollingCallBackObject && 
                        self.scrollingCallBackObject[currentDataType] && 
                        self.scrollingCallBackObject[currentDataType]();
                }
            }
        }
    }

    updatePaginationCallback(callbackPropertyName: string, scrollingCallBack: object) {
        this.scrollingCallBackObject[callbackPropertyName] = scrollingCallBack;
    }

}