export const environment = {
    production: true,

    // Added for FS
    fundspringHostname: "staging.fundspring.io",
    fundspringProjectPath: "projects",

    // Added for VD
    vdAPIEndpointHostname: "api.staging.securecode.io",
    vdAPIEndpointProjectPath: "project",
    vdAPIEndpointDependenciesPath: "vulnerability/dependencies",
    vdAPIEndpointDependencyGraphPath: "vulnerability/dependencies/tree",
    vdAPIEndpointLicensesPath: "vulnerability/licenses",
    vdAPIEndpointIssuesPath: "vulnerability/issues",
    vdAPIEndpointSummaryPath: "vulnerability/summary",
    vdAPIEndpointTaskPath: "task",

    // added for static analysis
    vdAPIEndpointStaticIssuesPath: "staticanalysis/issues",
    vdAPIEndpointStaticSummaryPath: "staticanalysis/summary"
};
