import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Issue, ProjectIssue } from '../models/issues';
import { Globals } from '../globals';

@Injectable({
    providedIn: 'root'
})

export class IssuesService {
    constructor(
        private http: HttpClient,
        private globals: Globals
    ) { }

    getIssues (externalId: string, issueFilters: object) {
        return this.http.get<Issue[]>( [
            this.globals.getApiHostname(externalId),
            this.globals.getApiPath('issues')
        ].join('/'), {
            params : new HttpParams({
                fromObject: this.globals.getParamsFromFilters(issueFilters)
            })
        } );
    }

    getProjectIssues (externalId: string, projectIssueFilters: object) {
        return this.http.get<ProjectIssue[]>( [
            this.globals.getApiHostname(externalId),
            this.globals.getApiPath('staticanalysis/issues')
        ].join('/'), {
            params : new HttpParams({
                fromObject: this.globals.getParamsFromFilters(projectIssueFilters)
            })
        } );
    }

    getIssuesCSV (externalId: string, issueFilters: object) {
        window.open([
            this.globals.getApiHostname(externalId),
            this.globals.getApiPath('issues')
        ].join('/')+"?format=csv");
    }

    getProjectIssuesCSV (externalId: string, issueFilters: object) {
        window.open([
            this.globals.getApiHostname(externalId),
            this.globals.getApiPath('staticanalysis/issues')
        ].join('/')+"?format=csv");
    }
}
