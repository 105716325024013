import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from "@angular/common/http";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GoogleChartsModule } from 'angular-google-charts';
import { MarkdownModule, MarkedOptions, MarkedRenderer } from 'ngx-markdown';
import { FormsModule } from '@angular/forms';
import { PrismModule } from '@ngx-prism/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { OverviewComponent } from './overview/overview.component';
import { HistoryComponent } from './history/history.component';
import { IssuesComponent } from './issues/issues.component';
import { DetailsComponent } from './details/details.component';
import { DependenciesComponent } from './dependencies/dependencies.component';
import { LicensesComponent } from './licenses/licenses.component';
import { NotValidPageComponent } from './not-valid-page/not-valid-page.component';
import { Globals } from './globals';
import { MapPipe } from './pipes/map.pipe';
import { JoinPipe } from './pipes/join.pipe';
import { DepGraphNodeComponent } from './dep-graph-node/dep-graph-node.component';

export function markedOptionsFactory(): MarkedOptions {
  var renderer = new MarkedRenderer();
  var linkRenderer = renderer.link;

  renderer.link = (href, title, text) => {
    var html = linkRenderer.call(renderer, href, title, text);
    return html.replace(/^<a /, '<a target="_blank" ');
  };

  return {
    renderer: renderer
  };
}

@NgModule({
  declarations: [
    AppComponent,
    OverviewComponent,
    HistoryComponent,
    IssuesComponent,
    DetailsComponent,
    DependenciesComponent,
    LicensesComponent,
    NotValidPageComponent,
    MapPipe,
    JoinPipe,
    DepGraphNodeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    GoogleChartsModule,
    MarkdownModule.forRoot({
      markedOptions: {
        provide: MarkedOptions,
        useFactory: markedOptionsFactory,
      },
    }),
    HttpClientModule,
    FormsModule,
    PrismModule
  ],
  providers: [
      Globals
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
