import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'join'
})
export class JoinPipe implements PipeTransform {

  transform(value: any, delimiter: String = ''): any {
    if (!value) {
      return value;
    }
    return value.join(delimiter);
  }

}
