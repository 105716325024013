import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { OverviewService } from '../services/overview.service';
import { Globals } from '../globals';

@Component({
    selector: 'app-history',
    templateUrl: './history.component.html',
    styleUrls: ['./history.component.scss']
})

export class HistoryComponent implements OnInit {
    projectInfo = null;
    projectIntervalHolder = null;

    taskLoaded = false;

	taskData = null;
    diversityScanArray = [];
    securityScanArray = [];
    staticScanArray = [];

    constructor(
        private route: ActivatedRoute,
        private OverviewService: OverviewService,
        private Globals: Globals,
        private location: Location
    ) {}

    ngOnInit() {
        this.projectInfo = this.Globals.projectInfo;
        this.Globals.checkForProjectInfo(this.route.snapshot.paramMap.get('externalId'));
        this.getTaskData();

        this.projectIntervalHolder = setInterval(()=>{
            this.projectInfo = this.Globals.projectInfo;
            if(
                this.Globals.getAppLoaded()
            ){
                clearInterval(this.projectIntervalHolder);
            }
        }, 1000);
    }

    getTaskData(): void {
        this.OverviewService.getTaskData( this.route.snapshot.paramMap.get('externalId') )
            .subscribe( data => {
                this.taskData = data;
                for(var i=0;i<this.taskData.length;i++){
                    switch( this.taskData[i].taskType ){
                        case "snyk-vulnerability-scanning":
                            this.securityScanArray.push(this.taskData[i]);
                            break;
                        case "static-analysis-scanning":
                            this.staticScanArray.push(this.taskData[i]);
                            break;
                        case "diversity-scanning":
                            this.diversityScanArray.push(this.taskData[i]);
                            break;
                    }
                }

                this.taskLoaded = true;
            });
    }
}