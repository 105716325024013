import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'map'
})
export class MapPipe implements PipeTransform {

  transform(value, ...args): any {
    if (!value) {
      return value;
    }

    let newValue = value;
    for (const func of args) {
      if (func === 'trim') {
        newValue = newValue.map(v => v.trim());
      }
    }
    return newValue;
  }

}
