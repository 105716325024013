import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Details } from '../models/details';
import { Globals } from '../globals';

@Injectable({
    providedIn: 'root'
})

export class DetailsService {
    constructor(
        private http: HttpClient,
        private globals: Globals
    ) { }

    getIssues (externalId: string, detailFilters: object) {
        return this.http.get<Details[]>( [
            this.globals.getApiHostname(externalId),
            this.globals.getApiPath('issues')
        ].join('/') , {
            params : new HttpParams({
                fromObject: this.globals.getParamsFromFilters(detailFilters)
            })
        });
    }
}
