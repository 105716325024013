import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { Details } from '../models/details';
import { DetailsService } from '../services/details.service';
import { Globals } from '../globals';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})

export class DetailsComponent implements OnInit {
    details: Details[];
    detailsLoaded = false;
    projectInfo = null;
    projectIntervalHolder = null;

    // for filters
    detailFiltersString = "";
    detailsFilters = {
        severity : {
            high:true,
            medium:true,
            low:true
        },
        status : {
            open : true,
            fixed : true
        }
    };

    constructor(
        private route: ActivatedRoute,
        private DetailsService: DetailsService,
        private Globals: Globals,
        private location: Location
    ) { 
    }

    getIssues(): void {
        this.DetailsService.getIssues( 
            this.route.snapshot.paramMap.get('externalId'),
            this.detailsFilters
        )
            .subscribe( data => {
                this.details = data;
                this.detailsLoaded=true;
            });
    }

    updateFilters():void {
        this.detailFiltersString = this.Globals.updateFilterLanguage(this.detailsFilters);
        
        this.getIssues();
    }

    ngOnInit() {
        this.Globals.checkForProjectInfo(this.route.snapshot.paramMap.get('externalId'));
        this.projectInfo = this.Globals.projectInfo;
        this.getIssues();

        this.projectIntervalHolder = setInterval(()=>{
            this.projectInfo = this.Globals.projectInfo;
            if(
                this.Globals.getAppLoaded()
            ){
                clearInterval(this.projectIntervalHolder);
            }
        }, 1000);
    }

}
