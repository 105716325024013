import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';

import { License } from '../models/license';
import { Dependency } from '../models/dependency';
import { LicensesService } from '../services/licenses.service';
import { DependenciesService } from '../services/dependencies.service';
import { Globals } from '../globals';

@Component({
    selector: 'app-licenses',
    templateUrl: './licenses.component.html',
    styleUrls: ['./licenses.component.scss'],
    providers: [ NgbPopoverConfig ]
})

export class LicensesComponent implements OnInit {
    licenses: License[];
    licenseTypeFilterMap = {};
    licenseTypeFilterNameArray = [];
    licensesLoaded = false;
    projectInfo = null;
    projectIntervalHolder = null;

    dependencies: Dependency[];
    dependenciesLoaded = false;
    isLoadingDependencies = false;
    isDependenciesEnded = false;
    limit = 25;
    offset = 0;
    dependenciesCollection = [];

    isLoadingLicenses = false;

    constructor(
        private route: ActivatedRoute,
        private licensesService: LicensesService,
        private dependenciesService: DependenciesService,
        private globals: Globals,
    ) { }

    getLicenses() {
        if (this.isLoadingLicenses) {
            return false;
        }

        this.isLoadingLicenses = true;

        this.licensesService
            .getLicenses(this.route.snapshot.paramMap.get('externalId'), {})
            .subscribe( data => {
                if (data) {
                    this.licenses = data.map((json) => new License(json));
                }

                this.licensesLoaded = true;
                this.isLoadingLicenses = false;
                this.createLicenseList();
            });
    }

    getDependencies() {
        if (this.isLoadingDependencies) {
            return false;
        }

        const self = this;
        this.isLoadingDependencies = true;

        this.dependenciesService.getDependencies(
            this.route.snapshot.paramMap.get('externalId'),
            {
                limit: this.limit,
                offset: this.offset,
            }
        ).subscribe(data => {
            this.dependencies = data.map((json) => new Dependency(json));
            if (this.dependencies) {
                if (this.dependencies.length) {
                    this.dependenciesCollection = this.dependenciesCollection.concat(this.dependencies);

                    this.globals.updatePaginationCallback('dependencies', function () {
                        self.offset += self.limit;
                        self.getDependencies();
                    });
                } else {
                    this.globals.updatePaginationCallback('dependencies', function () {
                        self.isDependenciesEnded = true;
                        self.globals.updatePaginationCallback('dependencies', function () { });
                    });
                }
            }

            this.dependenciesLoaded = true;
            this.isLoadingDependencies = false;
        });
    }

    getDependenciesCSV(): void {
        this.dependenciesService.getDependenciesCSV(this.route.snapshot.paramMap.get('externalId'));
    }

    createLicenseList(): void {
        if (!this.licenses) {
            return;
        }

        for (let i = 0; i < this.licenses.length; i ++) {
            if (!this.licenseTypeFilterMap[this.licenses[i].name]) {
                this.licenseTypeFilterNameArray.push(this.licenses[i].name);
                this.licenseTypeFilterMap[this.licenses[i].name] = this.licenses[i];
            } else {
                this.licenseTypeFilterMap[this.licenses[i].name].push(this.licenses[i]);
            }
        }
    }

    getLicensesCSV(): void {
        this.licensesService.getLicensesCSV( this.route.snapshot.paramMap.get('externalId') );
    }

    ngOnInit() {
        this.projectInfo = this.globals.projectInfo;
        this.globals.checkForProjectInfo(this.route.snapshot.paramMap.get('externalId'));
        this.getLicenses();
        this.getDependencies();

        this.projectIntervalHolder = setInterval(() => {
            this.projectInfo = this.globals.projectInfo;
            if (this.globals.getAppLoaded()) {
                clearInterval(this.projectIntervalHolder);
            }
        }, 1000);
    }
}
