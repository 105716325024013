import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-dep-graph-node',
  templateUrl: './dep-graph-node.component.html',
  styleUrls: ['./dep-graph-node.component.scss']
})
export class DepGraphNodeComponent {
  // Array of bools, each index representing a node in the graph,
  // and the boolean representing if that node should show its children.
  private shouldChildBeExpanded: boolean[];

  private _data: Array<Object>;
  private _depth: number;

  @Input()
  set data(data: Array<Object>) {
    this._data = data;

    if (!this._data) {
      return;
    }
  }
  get data(): Array<Object> { return this._data; }

  @Input()
  set depth(depth: number) {
    this._depth = depth;

    if (this.depth === 0 || this.depth === 1 || this.depth === 2) {
      this.shouldChildBeExpanded = Array(this._data.length).fill(true);
    } else {
      this.shouldChildBeExpanded = Array(this._data.length).fill(false);
    }
  }
  get depth(): number { return this._depth; }

  toggle(i: number) {
    if (this.depth === 0) {
      return;
    }

    this.shouldChildBeExpanded[i] = !this.shouldChildBeExpanded[i];
  }

}
