import { Component, OnInit } from '@angular/core';
import { Globals } from '../globals';

@Component({
  selector: 'app-not-valid-page',
  templateUrl: './not-valid-page.component.html',
  styleUrls: ['./not-valid-page.component.scss']
})
export class NotValidPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
